import React from 'react'
import ImageGallery from 'react-image-gallery';
import gallery_external01 from '../images/gallery_external01.jpg'
import gallery_external01_preview from '../images/gallery_external01_preview.jpg'
import gallery_external02 from '../images/gallery_external02.jpg'
import gallery_external02_preview from '../images/gallery_external02_preview.jpg'
import gallery_external03 from '../images/gallery_external03.jpg'
import gallery_external03_preview from '../images/gallery_external03_preview.jpg'
import gallery_external04 from '../images/gallery_external04.jpg'
import gallery_external04_preview from '../images/gallery_external04_preview.jpg'
import gallery_external05 from '../images/gallery_external05.jpg'
import gallery_external05_preview from '../images/gallery_external05_preview.jpg'
import gallery_internal01 from '../images/gallery_internal01.jpg'
import gallery_internal01_preview from '../images/gallery_internal01_preview.jpg'
import gallery_internal02 from '../images/gallery_internal02.jpg'
import gallery_internal02_preview from '../images/gallery_internal02_preview.jpg'
import gallery_internal03 from '../images/gallery_internal03.jpg'
import gallery_internal03_preview from '../images/gallery_internal03_preview.jpg'
import gallery_internal04 from '../images/gallery_internal04.jpg'
import gallery_internal04_preview from '../images/gallery_internal04_preview.jpg'
import gallery_internal05 from '../images/gallery_internal05.jpg'
import gallery_internal05_preview from '../images/gallery_internal05_preview.jpg'
import gallery_internal06 from '../images/gallery_internal06.jpg'
import gallery_internal06_preview from '../images/gallery_internal06_preview.jpg'

import gallery_floorplanA from '../images/gallery_floorplanA.jpg'
import gallery_floorplanA_preview from '../images/gallery_floorplanA_preview.jpg'
import gallery_floorplanB from '../images/gallery_floorplanB.jpg'
import gallery_floorplanB_preview from '../images/gallery_floorplanB_preview.jpg'
import gallery_scenery01 from '../images/gallery_scenery01.jpg'
import gallery_scenery01_preview from '../images/gallery_scenery01_preview.jpg'
import gallery_scenery02 from '../images/gallery_scenery02.jpg'
import gallery_scenery02_preview from '../images/gallery_scenery02_preview.jpg'
import gallery_scenery03 from '../images/gallery_scenery03.jpg'
import gallery_scenery03_preview from '../images/gallery_scenery03_preview.jpg'
import gallery_scenery04 from '../images/gallery_scenery04.jpg'
import gallery_scenery04_preview from '../images/gallery_scenery04_preview.jpg'
import gallery_scenery05 from '../images/gallery_scenery05.jpg'
import gallery_scenery05_preview from '../images/gallery_scenery05_preview.jpg'
import gallery_scenery06 from '../images/gallery_scenery06.jpg'
import gallery_scenery06_preview from '../images/gallery_scenery06_preview.jpg'
import gallery_scenery07 from '../images/gallery_scenery07.jpg'
import gallery_scenery07_preview from '../images/gallery_scenery07_preview.jpg'
import gallery_scenery08 from '../images/gallery_scenery08.jpg'
import gallery_scenery08_preview from '../images/gallery_scenery08_preview.jpg'
import gallery_scenery09 from '../images/gallery_scenery09.jpg'
import gallery_scenery09_preview from '../images/gallery_scenery09_preview.jpg'


const images = [
  {original: gallery_external01, thumbnail: gallery_external01_preview},
  {original: gallery_external02, thumbnail: gallery_external02_preview},
  {original: gallery_external03, thumbnail: gallery_external03_preview},
  {original: gallery_external04, thumbnail: gallery_external04_preview},
  {original: gallery_external05, thumbnail: gallery_external05_preview},
  {original: gallery_internal01, thumbnail: gallery_internal01_preview},
  {original: gallery_internal02, thumbnail: gallery_internal02_preview},
  {original: gallery_internal03, thumbnail: gallery_internal03_preview},
  {original: gallery_internal04, thumbnail: gallery_internal04_preview},
  {original: gallery_internal05, thumbnail: gallery_internal05_preview},
  {original: gallery_internal06, thumbnail: gallery_internal06_preview},
  {original: gallery_floorplanA, thumbnail: gallery_floorplanA_preview},
  {original: gallery_floorplanB, thumbnail: gallery_floorplanB_preview},  
  {original: gallery_scenery01, thumbnail: gallery_scenery01_preview},
  {original: gallery_scenery02, thumbnail: gallery_scenery02_preview},
  {original: gallery_scenery03, thumbnail: gallery_scenery03_preview},
  {original: gallery_scenery04, thumbnail: gallery_scenery04_preview},
  {original: gallery_scenery05, thumbnail: gallery_scenery05_preview},
  {original: gallery_scenery06, thumbnail: gallery_scenery06_preview},
  {original: gallery_scenery07, thumbnail: gallery_scenery07_preview},
  {original: gallery_scenery08, thumbnail: gallery_scenery08_preview},
  {original: gallery_scenery09, thumbnail: gallery_scenery09_preview},
  
];
class ListingImageGallery extends React.Component {
  render() {    
    return (
      <ImageGallery items={images} />
    )
  }
}

export default ListingImageGallery
