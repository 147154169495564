import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import baselogo from '../images/logo_base.png';
import ListingImageGallery from './costablancaimagegallery';
import profilephoto from '../images/profile.jpg';
import services_listings from '../images/services_sunset.jpg';
import services_buyersagent from '../images/services_plungepool.jpg';
import list_costablanca_intro from '../images/listing_costablanca_intro.png';
import gallery_scenery07 from '../images/gallery_scenery07.jpg';
import gallery_external01 from '../images/gallery_external01.jpg';
import gallery_external02 from '../images/gallery_external02.jpg';
import gallery_floorplanA from '../images/gallery_floorplanA.jpg';
import gallery_floorplanB from '../images/gallery_floorplanB.jpg';
import gallery_external04 from '../images/gallery_external04.jpg';
import gallery_external05 from '../images/gallery_external05.jpg';
import gallery_scenery05 from '../images/gallery_scenery05.jpg';
import gallery_scenery08 from '../images/gallery_scenery08.jpg';
import gallery_internal06 from '../images/gallery_internal06.jpg';
import gallery_internal01 from '../images/gallery_internal01.jpg';
import gallery_internal02 from '../images/gallery_internal02.jpg';
import gallery_internal03 from '../images/gallery_internal03.jpg';
import gallery_internal04 from '../images/gallery_internal04.jpg';
import gallery_internal05 from '../images/gallery_internal05.jpg';
import gallery_external03 from '../images/gallery_external03.jpg';
import gallery_scenery04 from '../images/gallery_scenery04.jpg';
import gallery_scenery01 from '../images/gallery_scenery01.jpg';

const USER_API = process.env.API_ENDPOINT
class Main extends React.Component {  
  state = {
    name: "",
    phone: "",
    email: "",
    message: ""
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = event => {        
    event.preventDefault()
    let formData = {};
    formData['name']= this.state.name;
    formData['email']= this.state.email;
    formData['phone']= this.state.phone;
    formData['subject']= "Form";
    formData['desc']= this.state.message;
    
    var requestConfig = {
        method: 'POST',
        body: JSON.stringify(formData),        
        mode: "cors",
        cache: "no-cache",
        headers: new Headers({
          "Content-Type": "application/json"
        })
    };
    // console.log(JSON.stringify(formData))
    fetch(USER_API, requestConfig).then(response => response.json()            
    ).then(data => {
        console.log(data);        
        this.props.onOpenArticle('thankyou')
    }).catch(error => {
        console.log(error);
    });
  }
  resetform = event =>{
    this.setState({
      name: "",
      phone: "",
      email: "",
      message: ""
    })
  }
  render() {
    let close = <div className="close" role="button" tabIndex={0}  tabIndex="0" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} ></div>
    let altClose = <div className="close" role="button" tabIndex={0}  tabIndex="0" onClick={() => { this.props.onAltClose() }} onKeyDown={() => { this.props.onAltClose() }} ></div>
    console.log(this.props.windowwidth)
    let gridwidth = 6;
    if(this.props.windowwidth < 480) {gridwidth = 12;}

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? { display: 'flex' } : { display: 'none' }} >
        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <Grid container spacing={1} >
            <Grid item xs={12}>
              <h2 className="major">About</h2>
            </Grid>
            <Grid item xs={12}>
              <p>Jason Mumford Private Realtor is at your disposal. With decades of experience working with high net worth individuals, our team offers a dedicated service supplying quality real estate around the Mediterranean.  With an international clientele, we strive to bring you a level of expertise and services worthy level you expect. With serenity and confidentiality, we can work with you on all your real estate projects.</p>              
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={gridwidth}>
                  <img className="listing_img" src={profilephoto} alt="" />              
                </Grid>
                <Grid item xs={gridwidth}>
                  <p>Our principal, Jason Mumford, works directly with each client to ensure they receive the service they expect and deserve. Throughout his career, Jason has honed his marketing skills, developing a focused approach which lets the features of a property speak for themself. Jason has built relationships across the world, from the Mediterranean to London and all the way to Australiasia.</p>
                  <br />
                  <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onChangeArticle('contact') }} onKeyDown={() => { this.props.onChangeArticle('contact') }} >Contact us today to talk more &#8250;  </span>
                </Grid>                
              </Grid>
            </Grid>            
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} > &#8249; Back  </span>                 
                </Grid>
                <Grid item xs={7}>                  
                </Grid>
                <Grid item xs={3}>
                  <img className="wrapper_logo" src={baselogo} alt="" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {close}
        </article>
        <article id="services" className={`${this.props.article === 'services' ? 'active' : ''} ${ this.props.articleTimeout ? 'timeout' : '' }`} style={{ display: 'none' }}>                    
          <Grid container spacing={1} >
            <Grid item xs={12}>
              <h2 className="major">Services</h2>
            </Grid>
            <Grid item xs={12}>            
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <h3>Property Marketing</h3>
                </Grid>
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>                    
                    <Grid item xs={12}>
                      <img className="services_img" src={services_listings} alt="" />
                    </Grid> 
                  </Grid>                 
                </Grid>
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>                    
                    <Grid item xs={12}>
                      <p>Property marketing is our speciality, focusing on highlighting the details of each listing that make it unique. We believe in a higher industry standard and pride ourselves on providing a fresh, innovative and enthusiastic approach to all your real estate needs. Our team will work with you to develop a tailored servicing arrangement that is responsive to your needs and expectations.</p>
                      <p>We create simple and effective communication that cuts through the messaging clutter. Our mix of digital and traditional media strategically targets buyers’ imagination and gets them hooked with great storytelling. We utilise a direct response approach, designing a strategy to provide maximum response for your dollar.</p>
                      <p>Everything we do is prequalified to get a return on your investment by selling the benefits of your development. We showcase the best of your project and encourage a call to action. We create engaging content that brings your brand to life and ensure your properties are seen before your competitors are.</p>
                    </Grid>
                  </Grid>                  
                </Grid>
              </Grid>              
            </Grid>
            <Grid item xs={12} style={{height:"5vh"}}>
            </Grid>
            <Grid item xs={12}>            
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <h3>Buyers Agent</h3>
                </Grid>                
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>                    
                    <Grid item xs={12}>
                      <p>We love real estate, and nothing makes us happier than helping someone find the right property for the next phase of their lives. We also understand what it’s like to be searching for property and not finding the one that’s truly right for you. It can be an emotional and stressful time; you need experts on your side acting in your best interests.</p>
                      <p>When acting as an independent Buyers Agent, we only work for the buyer. We remain objective when evaluating a property and ensure our customers are aware of current market conditions and expected property values. Knowing what to look for and what to avoid is crucial when buying property. The last thing our clients need is to find potential issues when they take over their new investment. </p>
                    </Grid>
                    <Grid item xs={12}>
                      <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onChangeArticle('contact') }} onKeyDown={() => { this.props.onChangeArticle('contact') }} >Contact Today &#8250;  </span>
                    </Grid>
                  </Grid>                  
                </Grid>
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>                    
                    <Grid item xs={12}>
                      <img className="services_img" src={services_buyersagent} alt="" />
                    </Grid> 
                  </Grid>                 
                </Grid>
              </Grid>              
            </Grid>            
            <Grid item xs={12}>
              <Grid container spacing={1}>
              <Grid item xs={2}>
                <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} > &#8249; Back  </span>                 
                </Grid>
                <Grid item xs={7}>
                </Grid>
                <Grid item xs={3}>
                  <img className="wrapper_logo" src={baselogo} alt="" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>        
          {close}
        </article>
        <article id="listings" className={`${this.props.article === 'listings' ? 'active' : ''} ${ this.props.articleTimeout ? 'timeout' : '' }`} style={{ display: 'none' }} >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <h1 className="major">Listings</h1>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <h3 className="major">Costa Blanca</h3>   
                </Grid>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onChangeArticle('costablanca') }} onKeyDown={() => { this.props.onChangeArticle('costablanca') }}>
                    <img className="listing_img" src={list_costablanca_intro} alt="" />
                  </span>
                </Grid>
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <p>Located on the stunning Spanish east coast, an hour drive or 95 km from Alicante airport, this breath-taking location offers all one needs for a fun and relaxing holiday home, a retirement property or investment property. Sunny days most of the year round, pristine beaches, bars, restaurants, sporting clubs and plenty more. The laid-back lifestyle of Costa Blanca is all yours to enjoy.</p>                  
                    </Grid>
                    <Grid item xs={12}>                      
                      <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onChangeArticle('costablanca') }} onKeyDown={() => { this.props.onChangeArticle('costablanca') }}>Read More &#8250; </span>                        
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>              
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} > &#8249; Back  </span>                 
                </Grid>
                <Grid item xs={7}>                  
                </Grid>
                <Grid item xs={3}>
                  <img className="wrapper_logo" src={baselogo} alt="" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {close}
        </article>
        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${ this.props.articleTimeout ? 'timeout' : '' }`} style={{ display: 'none' }} >
          <h2 className="major">Contact</h2>
          <form id="contact-form" method="post" action='#' onSubmit={this.handleSubmit}>
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name-input" value={this.state.name} onChange={this.handleInputChange}/>
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email-input" value={this.state.email} onChange={this.handleInputChange}/>
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="description-input" rows="4" value={this.state.message} onChange={this.handleInputChange}></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} />
              </li>
              <li>
                <input type="reset" value="Reset" onClick={() => { this.resetform() }} onKeyDown={() => { this.resetform() }} />
              </li>
            </ul>
          </form>
          <ul className="icons">            
            <li>
              <a href="https://www.facebook.com/jasonmumfordprivaterealtor/" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/jason_mumford" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/jason-mumford-523b2258/" className="icon fa-linkedin" >
                <span className="label">LinkedIn</span>
              </a>
            </li>
          </ul>
          <ul className="article-list">
            <li>
              <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('privacy') }} onKeyDown={() => { this.props.onChangeArticle('privacy') }}>Privacy Policy </span>
              {/* <a href="/privacy"><span className="legalnavitems">Privacy Policy</span></a>               */}
            </li>
            <li>
              <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('terms') }} onKeyDown={() => { this.props.onChangeArticle('terms') }}>Terms &amp; Conditions </span>
              {/* <a href="/terms"><span className="legalnavitems">Terms &amp; Conditions</span></a>               */}
            </li>
          </ul>
          <div className="wrapper_logo_container" >
            <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} > &#8249; Back  </span>
            <img className="wrapper_logo" src={baselogo} alt="" />            
          </div>
          {altClose}
        </article>
        <article id="thankyou" className={`${this.props.article === 'thankyou' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">Thank You</h2>          
          <p>Thank you for sending us a message, we love hearing from you. You will receive a confirmation email shortly and we will endevour to get back to you as soon as possible.</p>          
          <div className="wrapper_logo_container" >
            <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} > &#8249; Back  </span>
            <img className="wrapper_logo" src={baselogo} alt="" />
          </div>
          {close}
        </article>
        <article id="privacy" className={`${this.props.article === 'privacy' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h1 className="section-headline" style={{color:"#ffffff"}}>Privacy Policy</h1>                                  
          <ul>
              <li>Thank you for visiting the TenTenths Digital website. We protect the privacy of our users and respect their personal information belongs to them. This policy outlines how use information collected through this website.</li>
              <li>The term "Personal Information" in this policy refers to any information from which a user’s identity can be reasonably required. We do not collect Personal Information about users who utilise this website.</li>
              <li>Users can use this website without providing any Personal Information or informing TenTenths Digital. Contact form, newsletter or feedback submissions do not require a user to enter any personal information. If a user makes contact, we will collect any identifying information provided, such as a name, email or phone number. Please do not give us other personal or sensitive information.</li>
              <li>This website is hosted in Australia and cached globally utilising secure facilities. To protect the privacy of personal information we collect, physical, technical and administrative safeguards are maintained.  Our employees are trained regarding the importance of confidentiality and maintaining the privacy and security of user information. Access to a user’s Personal Information is restricted to those employees who provide benefits or services to users. TenTenths Digital update and test our security technology on an ongoing basis.</li>
              <li>To improve a user’s experience, we use 'cookies'. Cookies are an industry standard and most major web sites use them. A cookie is a small amount of data that this website places on a user’s computer as a tool to remember any preferences changed in relation to our site. Refusing the use of cookies is possible by selecting the related browser settings. Please note that this will restrict the full functionality of this website.</li>
              <li>This website contains links to external websites. Please be aware that we are not responsible for the privacy policies and actions of other sites. A user is responsible for reviewing the privacy policy of other sites if they follow external links.</li>
              <li>This website uses Google Analytics, which transmits website traffic data to Google servers in the United States. Google Analytics does not identify individual users or associate a user’s IP address with any other data held by Google. TenTenths Digital use reports provided by Google Analytics to help understand website traffic and webpage usage. By using this website, a user consents to the processing of data about the user by Google in the manner described in Google's Privacy Policy and for the purposes set out above. A user can opt out of Google Analytics if the user disables or refuses the cookie, disable JavaScript, or use the opt-out service provided by Google- external site.</li>
              <li>TenTenths Digital utilises social media sites Facebook, Instagram, LinkedIn, Twitter and others. If a user chooses to interact with information from this website through these services, the user should review the privacy policy of that service. If a user is a member of a social media site which interfaces with this website, the interface may allow the social media site to connect visits to this site with other Personal Information.</li>
              <li>This website is bound by the provisions of the Privacy Act 1988 (Cth, including the Australian Privacy Principles.</li>
          </ul>
          <hr style={{border: '1px solid #505050', maxWidth:"80vw"}}/>
          <div className="wrapper_logo_container" >
            <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} > &#8249; Back  </span>
            <img className="wrapper_logo" src={baselogo} alt="" />
          </div>
          {altClose}
        </article>
        <article id="terms" className={`${this.props.article === 'terms' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h1 className="section-headline" style={{color:"#ffffff"}}>Terms &amp; Conditions</h1>            
          <h3 style={{color: "#909090"}}>Site Content Disclaimer</h3>
          <ul>
              <li>Jason Mumford Private Realtor (‘The Company’) has taken all reasonable steps to ensure the accuracy of information on this website. However we can give no warranty regarding the accuracy or completeness of the content of this website. Hence we accept no liability for any losses or damages (whether direct, indirect, special, consequential or otherwise) arising out of errors or omissions contained in this web site.</li>
              <li>The Company reserves the right to update, add, amend, remove, replace or change any part of the web site content, including but not limited to functionality and navigation at any time without prior notice. The Company shall not be liable for distortion of data arising from any technical fault including transmission errors, technical defects, interruptions, third party intervention or viruses.</li>
          </ul>
          <h3 style={{color: "#909090"}}>Copyright Laws</h3>
          <ul>
              <li>The information you receive online from this site is protected by the copyright laws of Spain and the European Union. The copyright laws prohibit any copying, redistributing, retransmitting, or repurposing of any copyright-protected material without express written permission from The Company or the relevant copyright owner. If given, permission will be subject to the requirement that the copyright owner’s name and interest in the material be acknowledged when the material is reproduced or quoted, in whole or in part.</li>
          </ul>            
          <h3 style={{color: "#909090"}}>Third Party Links</h3>
          <ul>
              <li>This website contains links to other web sites which are hosted and maintained by third parties. The Company makes no representation as to the accuracy, completeness or relevance of the information contained on such Third Party Sites. You follow links to such sites at your own risk, and we will not be liable for any loss or damage rising from your reliance upon or use of Third Party Sites.</li>
          </ul>
          <h3 style={{color: "#909090"}}>Exclusion Of Liability</h3>
          <ul>
              <li>The Company cannot guarantee that this web site shall be available on an uninterrupted basis and we will not be liable for any losses, costs or damages resulting from this web site not being accessible or for delays in access. Access to and use of this web site is at the user’s own risk and we cannot warrant that the use of this web site or any material downloaded from it will not cause damage to any property. In addition, we accept no liability in respect of losses or damages arising out of changes made to the content of this web site by unauthorised third parties.</li>
          </ul>            
          <hr style={{border: '1px solid #505050', maxWidth:"80vw"}}/>
          <div className="wrapper_logo_container" >
            <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} > &#8249; Back  </span>
            <img className="wrapper_logo" src={baselogo} alt="" />
          </div>
          {altClose}
        </article>
        <article id="costablanca" className={`${this.props.article === 'costablanca' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>                    
          <Grid container spacing={0} >
            <Grid item xs={12}>
              <h1 className="section-headline" style={{color:"#ffffff"}}>Costa Blanca</h1>  
            </Grid>            
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <p>Located on the stunning Spanish east coast, an hour drive or 95 km from Alicante airport, this breath-taking location offers all one needs for a fun and relaxing holiday home, a retirement property or investment property. Sunny days most of the year round, pristine beaches, bars, restaurants, sporting clubs and plenty more. The laid-back lifestyle of Costa Blanca is all yours to enjoy. Click on images to zoom in.</p>
                </Grid>
              </Grid>
              <Grid className="listing_gridsection" container spacing={0}>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                    <img className="listing_img" src={gallery_external01} alt="" />
                  </span>
                </Grid>
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <p>The Spanish-style apartments have access to the hotel restaurants, pool, spa, secure parking, concierge, manicured gardens, house cleaning, and the option to create a personalized concierge service for all your needs. The hotel can also manage your property as a holiday rental or for friends and family who may visit.</p>                  
                    </Grid>                    
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="listing_gridsection" container spacing={0}>
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <p>Walk along the white sandy beaches as the waves lap at your feet and the wind blows across your face. Bliss and tranquility is a short one kilometre from your home. Costa Blanca offers 240 km of coastline, with over 170 beaches and coves. An abundance of natural beauty at your doorstep, ready for you to explore.</p>                  
                    </Grid>     
                  </Grid>
                </Grid>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                    <img className="listing_img" src={gallery_scenery07} alt="" />
                  </span>                  
                </Grid>
              </Grid>
              <Grid className="listing_gridsection" container spacing={0}>
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                        <img className="listing_img" src={gallery_floorplanA} alt="" />
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                        <img className="listing_img" src={gallery_floorplanB} alt="" />
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <p>The apartments are 120-130 m² depending on which floor plan you choose. Plan A is single level and located on the ground floor. Plan B is on the second floor and comes with a solarium and the option to build a third room in the attic.</p>
                      <p>Both apartments have two bedrooms with built-in closets, two bathrooms, a family room, terrace and kitchen.</p>                  
                    </Grid>
                    <Grid item xs={12}>
                      <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                        <img className="listing_img" src={gallery_external02} alt="" />
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="listing_gridsection" container spacing={0}>
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <p>With various sports such as golf, tennis, swimming, hiking, sailing or something a little more extreme like kite surfing, scuba diving, cross country and downhill bike riding, the Costa Blanca has all you need to keep yourself healthy and happy. Also located two kilometres away is the local marina, where you can have your yacht or boat ready for those perfect days on the Mediterranean sea.</p>
                    </Grid>
                    <Grid item xs={12}>
                      <span role="button" tabIndex={0}  className="legalnavitems"  onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                        <img className="listing_img" src={gallery_external04} alt="" />
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                        <img className="listing_img" src={gallery_scenery05} alt="" />
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                        <img className="listing_img" src={gallery_scenery08} alt="" />
                      </span>
                    </Grid>
                  </Grid>
                </Grid>                
              </Grid>
              <Grid className="listing_gridsection" container spacing={0}>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                    <img className="listing_img" src={gallery_external05} alt="" />
                  </span>                  
                </Grid>
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <p>Enjoy the scents of rosemary, jasmine, pine, lavender and many other local plants from the manicured gardens while relaxing on your terrace, entertaining your friends and family in the warmth of the Mediterranean with a chilled cerveza or sangria. This splendid 20 m² terrace is accessible via the master bedroom, the family room and the garden.</p>                  
                    </Grid>                    
                  </Grid>
                </Grid>
              </Grid>
              <Grid className="listing_gridsection" container spacing={0}>
                <Grid item xs={gridwidth}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <p>Eating in is a great choice with a fully equipped kitchen with high quality appliances and the abundance of seasonal fresh fruits, vegetables, meats and seafood available daily from the local markets. Though with access to some of the best restaurants and tapas bars with stunning views of the Mediterranean, your appetite will always be full.</p>                  
                    </Grid>                    
                  </Grid>
                </Grid>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                    <img className="listing_img" src={gallery_internal06} alt="" />
                  </span>                   
                </Grid>
              </Grid>
              <Grid className="listing_gridsection" container spacing={0}>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                    <img className="listing_img" src={gallery_internal01} alt="" />
                  </span>                   
                </Grid>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0} className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onClick={() => { this.props.onChangeArticle('costablancaimages') }}>
                    <img className="listing_img" src={gallery_internal02} alt="" />
                  </span>                   
                </Grid>
              </Grid> 
              <Grid className="listing_gridsection" container spacing={0}>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0} className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                    <img className="listing_img" src={gallery_internal03} alt="" />
                  </span>                   
                </Grid>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                    <img className="listing_img" src={gallery_internal04} alt="" />
                  </span>                   
                </Grid>
              </Grid>
              <Grid className="listing_gridsection" container spacing={0}>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                    <img className="listing_img" src={gallery_internal05} alt="" />
                  </span>                   
                </Grid>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                    <img className="listing_img" src={gallery_external03} alt="" />
                  </span>                   
                </Grid>
              </Grid> 
              <Grid className="listing_gridsection" container spacing={0}>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                    <img className="listing_img" src={gallery_scenery04} alt="" />
                  </span>                   
                </Grid>
                <Grid item xs={gridwidth}>
                  <span role="button" tabIndex={0}  className="legalnavitems" onClick={() => { this.props.onChangeArticle('costablancaimages') }} onKeyDown={() => { this.props.onChangeArticle('costablancaimages') }}>
                    <img className="listing_img" src={gallery_scenery01} alt="" />
                  </span>                   
                </Grid>
              </Grid>              
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} > &#8249; Back  </span>                 
                </Grid>
                <Grid item xs={7}>                  
                </Grid>
                <Grid item xs={3}>
                  <img className="wrapper_logo" src={baselogo} alt="" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {altClose}
        </article>
        <article id="costablancaimages" className={`${this.props.article === 'costablancaimages' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>                    
          <Grid container spacing={1} >
            <Grid item xs={12}>
              <h1 className="section-headline" style={{color:"#ffffff"}}>Costa Blanca</h1>  
            </Grid>
            <Grid item xs={12}>
              <ListingImageGallery />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <span role="button" tabIndex={0}  className="listing_link" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} > &#8249; Back  </span>                 
                </Grid>
                <Grid item xs={7}>                  
                </Grid>
                <Grid item xs={3}>
                  <img className="wrapper_logo" src={baselogo} alt="" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {altClose}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onOpenArticle: PropTypes.func,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
  servicesContent: PropTypes.array,
  newsArticles: PropTypes.array
}

export default Main
